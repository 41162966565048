html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Orbitron';
  margin: 0;
  background: linear-gradient(180deg, #000000 0%, #003841 66.15%);
  color: #FFFFFF;
  /* width: 99vw; */
}

h1 {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 800;
  font-size: 2em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h2 {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 900;
  font-size: 1.5em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h3 {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

h4 {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 900;
  font-size: 2.5em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  h1 {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 800;
    font-size: 3.75em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    font-size: 2em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}

p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.01em;
}

@keyframes expand-icon {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 50px;
  }
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}